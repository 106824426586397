import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { FacilityInformationGetApiParameter } from './models/facility-information-api-parameter';
import { FacilityInformationGetApiResponse, FacilityInformationUpdateApiResponse } from './models/facility-information-api-response';

import { ServerlessApiProvider } from '../serverless-api';
import { HttpClient } from '@angular/common/http';
import { FacilityInformation } from './models/facility-information';

const BASE_URL = '/v1/backend/facility/facilityInformation';
const UPDATE_URL = `${BASE_URL}/update`;

/**
 * [満空情報]施設情報のプロバイダー.
 */
@Injectable()
export class FacilityInformationServiceProvider extends ServerlessApiProvider {

  private sessionSource: BehaviorSubject<FacilityInformation> = new BehaviorSubject<FacilityInformation>(null);

  private facilityInfo: FacilityInformation[];

  constructor(
    public http: HttpClient
  ) {
    super(http);
  }

  /**
   * セッションを取得する.
   */
  getSession(): FacilityInformation {
    return this.sessionSource.getValue();
  }

  /**
   * セッションを削除する.
   */
  deleteSession() {
    this.sessionSource.next(null);
  }

  /**
   * セッションを保存する.
   */
  saveSession(session: FacilityInformation) {
    this.sessionSource.next(session);
  }

  /**
   * 施設情報を取得する.
   */
  getFacilityInfo(): FacilityInformation[] {
    return this.facilityInfo;
  }

  /**
   * 施設情報をを削除する.
   */
  deleteFacilityInfo() {
    this.facilityInfo = [];
  }

  /**
   * 施設情報を保存する.
   */
  saveFacilityInfo(info: FacilityInformation[]) {
    this.facilityInfo = info;
  }

  /**
   * 最新の施設情報一覧取得処理.
   * 
   * @param id 施設ID
   * @returns Observable<FacilityInformationGetApiResponse>
   */
  public latest(id?: number): Observable<FacilityInformationGetApiResponse> {
    const params = { latest: 1 } as FacilityInformationGetApiParameter;
    if (id) {
      params["facilityId"] = id;
    }
    return this.post(BASE_URL, params);
  }

  /**
   * 施設情報の更新処理.
   * 
   * @param facilityId 施設ID
   * @param facilityStatusValue 満空情報状態値
   * @param lastUpdate 更新日時
   * @param post SNSリンク
   * @param hyperlink 投稿メッセージ
   * @returns Observable<FacilityInformationUpdateApiResponse>
   */
  public update(facilityId: number, facilityStatusValue: number, lastUpdate: number, post?: string, hyperlink?: string): Observable<FacilityInformationUpdateApiResponse> {
    const params = { 
      facilityId: facilityId,
      facilityStatusValue: facilityStatusValue,
      post: '',
      hyperlink: '',
      lastUpdate: lastUpdate
     } 
    if (post) {
      params["post"] = post;
    }
    if (hyperlink) {
      params["hyperlink"] = hyperlink;
    }
    return this.post(UPDATE_URL, params);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Const } from '../const';

import { environment } from '../../environments/environment';


/**
 * サーバレスAPIのプロバイダー.
 */
@Injectable()
export class ServerlessApiProvider {
  constructor(
    public http: HttpClient
  ) { }

  /**
   * パラメータをbodyに変換.
   *
   * @param parameter パラメータ
   * @return body
   */
  parameterToBody<T>(parameter: T): string {
    let body = '';
    let amp = '';
    Object.keys(parameter).map((key) => {
      body += amp + key + '=' + parameter[key];
      amp = '&';
    })
    return body;
  }

  /**
   * post実行.
   *
   * @param path パス
   * @param parameter パラメータ
   * @return レスポンス
   */
  post<T, V>(path: string, parameter: T): Observable<V> {
    const url = this.getUrl(path);
    const options = { headers: this.getHeader() };
    return this.http.post<V>(url, parameter, options).pipe(tap((res) => this.resultFail<V>(res)));
  }

  /**
   * get実行(1件取得).
   *
   * @param path パス
   * @return レスポンス
   */
  get<T>(path: string): Observable<T> {
    const url = this.getUrl(path);
    const options = { headers: this.getHeader() };
    return this.http.get<T>(url, options).pipe(tap((res) => this.resultFail<T>(res)));
  }

  /**
   * ヘッダー作成.
   * 
   * @returns new HttpHeaders
   */
  private getHeader(): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('content-type', 'application/json');
    return headers;
  }

  /**
   * URL取得.
   * 
   * @param path パス
   * @returns URL
   */
  private getUrl(path: string): string {
    return environment.setting.servicerApiUrl + path;
  }

  /**
   * 結果が0のエラー.
   * 
   * @param response APIレスポンス
   */
  private resultFail<T>(response: T): void {
    if (response['result'] === Const.ResponseResult.FAIL) {
      throw new Error(response['message']);
    }
  }
}

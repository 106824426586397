/**
 * loading タイプ.
 */
export const LoadingType = {
  /** 透明. */
  Transparency: 1,
  /** 車両検索. */
  VehicleSearch: 2,
  /** 顔認証. */
  FaceLogin: 3,
  /**配車予約 */
  VehicleReservation: 4
};

export type LoadingType = typeof LoadingType[keyof typeof LoadingType];

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { MasterData, StationForSearch, Keyword, Nickname, BoardingLocationInfo, stationMasterData } from './servicer/models/masterData';
import { Const } from '../../providers/const';
import { ApiResultType } from '../../providers/servicer/types/api-result-type';
import { UserV2ServiceProvider } from '../../providers/servicer/user-v2-service';
import { FacilityService } from '../../providers/serverless-api/facility/facility-service';

@Injectable({
  providedIn: 'root'
})
export class DeviceDataUpdateService {
  private readonly SERVICER_API_URL = environment.setting.servicerApiUrl;
  private readonly BACKEND_FILE_URL = environment.backendFileUrl;
  private readonly TIME_SCHEDULE_URL = environment.timeScheduleUrl;
  private readonly STATION_INFO_URL = environment.stationCsvUrl;
  private readonly CODE_ROW_COLUMN = 0;
  private readonly TYPE_ROW_COLUMN = 1;
  private readonly NAME_ROW_COLUMN = 2;
  private readonly YOMI_ROW_COLUMN = 3;
  private readonly DISTRICT_L_COLUMN = 4;
  private readonly DISTRICT_S_COLUMN = 5;
  private readonly LAT_ROW_COLUMN = 6;
  private readonly LON_ROW_COLUMN = 7;
  private readonly ADDRESS_ROW_COLUMN = 8;
  private readonly NICKNAME_COLUMN = 10;
  private readonly NICKNAME_KANA_COLUMN = 11;
  private readonly KEYWORD_COLUMN = 12;
  private readonly KEYWORD_KANA_COLUMN = 13;
  private readonly FARE_ZONE = 15;
  private readonly SPARE_REGULAR_STOPS_ID_COLUMN = 16;
  private readonly SPARE_DAYTIME_STOPS_ID_COLUMN = 17;
  private readonly SPARE_NIGHT_STOPS_ID_COLUMN = 18;
  private readonly FACILITY_ID_COLUMN = 19;
  private masterData: MasterData;

  constructor(
    public userV2Service: UserV2ServiceProvider,
    public facilityService: FacilityService
  ) { }

  /**
   * マスターデータの初期化
   * @returns true: 初期化成功 false:　初期化失敗
   */
  public initMasterData(): Observable<boolean> {
    return new Observable<boolean>((observe) => {
      this.userV2Service.guestLogin().subscribe((response) => {
        if (response.result !== ApiResultType.SUCCESS) {
          observe.next(false);
          return;
        }
        environment.token = response.guestLogin.token;
        // 満空情報用にログインしているユーザーのIDを保存する
        this.facilityService.setLogin_user_id(response.guestLogin.loginId);
        this.getDataFromS3Bucket().then(([timeSchedule, stationInfo]) => {
          if (timeSchedule && stationInfo) {
            this.setMasterData(timeSchedule, stationInfo);
            observe.next(true);
          } else {
            observe.next(false);
          }
        });
      });
    })
  }

  /**
   * マスターデータを取得
   * @returns マスターデータ
   */
  public getMasterData(): MasterData {
    return this.masterData;
  }

  /**
   * マスターデータをクリア
   */
  public clearMasterData(): void {
    this.masterData = this.getDefaultMasterData();
  }

  /**
   * AWSのS3バケットから営業時間と駅情報を取得
   */
  private async getDataFromS3Bucket(): Promise<[string[][], MasterData]> {
    return await Promise.all([
      this.getTimeSchedule(),
      this.getStationInfo()
    ]);
  }


  /**
   * 営業時間を取得
   * @returns 営業時間
   */
  private async getTimeSchedule(): Promise<string[][]> {
    const retrievalUrl = this.SERVICER_API_URL + this.BACKEND_FILE_URL + this.TIME_SCHEDULE_URL;
    const response = await fetch(retrievalUrl);

    if (response.ok) {
      return this.formatTimeSchedule(await response.text());
    } else {
      throw new Error(response.status.toString());
    }
  }

  /**
   * 画面表示用に営業時間を整形
   * @param timeSchedule 営業時間
   * @returns 画面表示用に整形した営業時間
   */
  private formatTimeSchedule(timeSchedule: string): string[][] {
    const stringList = timeSchedule.split(/\r\n|\r|\n/);
    const INDEX_0 = 0;
    const INDEX_1 = 1;

    let tmp: string[];
    let timeTmp: string[];
    let formattedTimeSchedule: string[][] = [];

    if (stringList.length) {
      for (let i = 1; i < stringList.length; i++) {
        if (stringList[i]) {
          tmp = stringList[i].split('：');
          timeTmp = tmp[INDEX_1].split(' - ');
          formattedTimeSchedule.push([tmp[INDEX_0], timeTmp[INDEX_0], timeTmp[INDEX_1]]);
        }
      }
    }
    return formattedTimeSchedule;
  }

  /**
   * 駅詳細情報の取得
   * @returns 駅詳細情報
   */
  private async getStationInfo(): Promise<MasterData> {
    const retrievalUrl = this.SERVICER_API_URL + this.BACKEND_FILE_URL + this.STATION_INFO_URL;
    const response = await fetch(retrievalUrl);

    if (response.ok) {
      return this.parseStationInfoCsvData(await response.text());
    } else {
      throw new Error(response.status.toString());
    }
  }

  /**
   * data.csvから駅情報を抽出し、格納する
   * @param csvData 
   * @returns MasterData
   */
  private parseStationInfoCsvData(csvData: string): MasterData {
    const tmp = csvData.split(/\r\n|\r|\n/);
    const WAITING_AREA_TYPE = 0;
    let localMasterData = this.getDefaultMasterData();

    const settingStorage = localStorage.getItem(Const.settingStorageKey);
    let parseSettingStorage = environment.setting;
    if (settingStorage) {
      parseSettingStorage = JSON.parse(settingStorage);
    }

    for (let columnsIdx = 1; columnsIdx < tmp.length; columnsIdx++) {
      //カンマ区切りで配列に分割
      let row = tmp[columnsIdx].split(',');
      const csvData: StationForSearch = {
        id: [row[this.SPARE_REGULAR_STOPS_ID_COLUMN], row[this.SPARE_DAYTIME_STOPS_ID_COLUMN], row[this.SPARE_NIGHT_STOPS_ID_COLUMN]],
        code: row[this.CODE_ROW_COLUMN],
        type: Number.parseInt(row[this.TYPE_ROW_COLUMN]),
        name: row[this.NAME_ROW_COLUMN],
        yomi: row[this.YOMI_ROW_COLUMN],
        address: row[this.ADDRESS_ROW_COLUMN],
        lat: Number.parseFloat(row[this.LAT_ROW_COLUMN]),
        lon: Number.parseFloat(row[this.LON_ROW_COLUMN]),
        districtL: row[this.DISTRICT_L_COLUMN],
        districtS: row[this.DISTRICT_S_COLUMN],
        nickname: row[this.NICKNAME_COLUMN].split('/'),
        nicknameKana: row[this.NICKNAME_KANA_COLUMN].split('/'),
        keyword: this.parseKeyword(row[this.KEYWORD_COLUMN]),
        keywordKana: this.parseKeyword(row[this.KEYWORD_KANA_COLUMN]),
        fareZone: row[this.FARE_ZONE],
        facilityId: row[this.FACILITY_ID_COLUMN].split('/').map(Number)
      }
      localMasterData['allStationInfo'].push(csvData);

      //乗車地の詳細情報を作成
      if (parseSettingStorage.stationCode && parseSettingStorage.stationCode === csvData.code) {
        const stationName = parseSettingStorage.displayName ? parseSettingStorage.displayName : csvData.name;
        const puStationInfo = {
          id: csvData.id,
          code: csvData.code,
          type: csvData.type,
          name: csvData.name,
          yomi: csvData.yomi,
          address: csvData.address,
          lat: csvData.lat,
          lon: csvData.lon,
          imgUrl: '',//取得先が未定のため一旦空にする
          fareZone: csvData.fareZone,
          assignName: parseSettingStorage.assignName,
          displayName: stationName
        }
        localMasterData['puStationInfo'] = puStationInfo;
        continue;
      }

      //大字リストを作成
      if (!localMasterData['districtLList'].includes(csvData.districtL)) {
        localMasterData['districtLList'].push(csvData.districtL);
      }

      //ニックネームとキーワードのリストを作成
      for (let nickNameIdx = 0; nickNameIdx < csvData.nickname.length; nickNameIdx++) {
        const nickname: Nickname = {
          name: csvData.nickname[nickNameIdx],
          yomi: csvData.nicknameKana[nickNameIdx],
          code: csvData.code,
          type: csvData.type
        };

        localMasterData['nicknameList'].push(nickname);

        if (!csvData.keyword) {
          continue;
        }

        for (let keyWordIdx = 0; keyWordIdx < csvData.keyword[nickNameIdx].length; keyWordIdx++) {
          let element = localMasterData['keywordList'].find((keyword) => keyword.word === csvData.keyword[nickNameIdx][keyWordIdx]);
          if (element) {
            element.nickName.push(nickname);
          } else {
            const keyword: Keyword = {
              word: csvData.keyword[nickNameIdx][keyWordIdx],
              yomi: csvData.keywordKana[nickNameIdx][keyWordIdx],
              nickName: new Array<Nickname>(nickname)
            };

            localMasterData['keywordList'].push(keyword);
          }
        }
      }

      //typeが0（待機場）の場合検索候補から外す
      if (csvData.type === WAITING_AREA_TYPE) {
        continue;
      }
    }
    return localMasterData;
  }

  /**
   * キーワードを'/'で分割
   *
   * @param keyword キーワード文字列
   * @returns キーワードリスト
   */
  private parseKeyword(keyword: string): string[][] {
    let keywords: string[][];
    if (!keyword) {
      return keywords
    }

    const regex = /\{([^\[\}\s]+)/g;
    const stopTypes = keyword.match(regex).map((s) => s.substring(1, s.length));

    if (!stopTypes) {
      return keywords;
    }

    keywords = new Array(stopTypes.length);
    stopTypes.forEach((keywordList, index) => {
      keywords[index] = keywordList.split('/');
    });

    return keywords;
  }

  /**
   * S3から取得した情報をmasterDataに保存
   * @param timeSchedule 営業時間
   * @param stationInfo  降車地選択で必要な情報
   */
  private setMasterData(timeSchedule: string[][], stationInfo: stationMasterData): void {
    const nowDate = new Date();

    // 次回更新期日を翌日0時に設定
    const tmpDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() + 1, 0, 0, 0);

    this.masterData = {
      allStationInfo: stationInfo.allStationInfo,
      keywordList: stationInfo.keywordList,
      nicknameList: stationInfo.nicknameList,
      districtLList: stationInfo.districtLList,
      nextLoadDate: tmpDate.getTime(),
      puStationInfo: stationInfo.puStationInfo,
      timeSchedule: timeSchedule
    };

    const storageKey = 'masterTable';
    localStorage.removeItem(storageKey);
    localStorage.setItem(storageKey, JSON.stringify(this.masterData));
  }

  /**
   * デフォルトマスターデータの取得
   * @returns マスターデータ
   */
  private getDefaultMasterData(): MasterData {
    return {
      allStationInfo: [],
      keywordList: [],
      nicknameList: [],
      districtLList: [],
      nextLoadDate: 0,
      puStationInfo: {} as BoardingLocationInfo,
      timeSchedule: []
    }
  };
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ServerlessApiProvider } from '../serverless-api';
import { HttpClient } from '@angular/common/http';
import { FacilitySelectHistory } from './models/facility-select-history';
import { FacilitySelectHistoryGetApiResponse, FacilitySelectHistoryUpdateApiResponse } from './models/facility-select-history-api-response';
import { FacilitySelectHistoryGetApiParameter } from './models/facility-select-history-api-parameter';

const BASE_URL = '/v1/backend/facility/facilitySelectHistory';
const UPDATE_URL = `${BASE_URL}/update`;

/**
 * [満空情報] 選択履歴のプロバイダー.
 */
@Injectable()
export class FacilitySelectHistoryServiceProvider extends ServerlessApiProvider {

  private sessionSource: BehaviorSubject<FacilitySelectHistory> = new BehaviorSubject<FacilitySelectHistory>(null);

  constructor(
    public http: HttpClient
  ) {
    super(http);
  }

  /**
   * セッションを取得する.
   */
   getSession(): FacilitySelectHistory {
    return this.sessionSource.getValue();
  }

  /**
   * セッションを削除する.
   */
  deleteSession() {
    this.sessionSource.next(null);
  }

  /**
   * セッションを保存する.
   */
  saveSession(session: FacilitySelectHistory) {
    this.sessionSource.next(session);
  }

  /**
   * 選択履歴一覧取得処理.
   * 
   * @param facilityId 施設ID
   * @param startDt 検索範囲開始日時
   * @param endDt 検索範囲終了日時
   * @returns Observable<FacilitySelectHistoryGetApiResponse>
   */
  public getHistory(startDt: string, endDt: string, facilityId?: number): Observable<FacilitySelectHistoryGetApiResponse> {
    const params = { facilityId: facilityId, startDt: startDt, endDt: endDt } as FacilitySelectHistoryGetApiParameter;
    if (facilityId) {
      params["facilityId"] = facilityId;
    }
    return this.post(BASE_URL, params);
  }

  /**
   * 選択履歴の更新処理.
   * 
   * @param facilityId 施設ID
   * @param visitorId 訪問者ID
   * @param pickUpPointLat 希望乗車地緯度
   * @param pickUpPointLon 希望乗車地経度
   * @param dropOffPointLat 希望降車地緯度
   * @param dropOffPointLon 希望降車地経度
   * @param selectDt 選択日時
   * @returns Observable<FacilitySelectHistoryUpdateApiResponse>
   */
  public update(facilityId: number, visitorId: string, pickUpPointLat: number, pickUpPointLon: number, dropOffPointLat: number, dropOffPointLon: number, selectDt: number): Observable<FacilitySelectHistoryUpdateApiResponse> {
    const params = {
      facilityId: facilityId,
      visitorId: visitorId,
      pickUpPointLat: pickUpPointLat,
      pickUpPointLon: pickUpPointLon,
      dropOffPointLat: dropOffPointLat,
      dropOffPointLon: dropOffPointLon,
      selectDt: selectDt
     }
    return this.post(UPDATE_URL, params);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServerlessApiProvider } from '../serverless-api';
import { HttpClient } from '@angular/common/http';
import { FacilitySummaryViewHistory } from './models/facility-summary-view-history';
import { FacilitySummaryViewHistoryGetApiResponse, FacilitySummaryViewHistoryUpdateApiResponse } from './models/facility-summary-view-history-api-response';
import { FacilitySummaryViewHistoryGetApiParameter } from './models/facility-summary-view-history-api-parameter'; 

const BASE_URL = '/v1/backend/facility/facilitySummaryViewHistory';
const UPDATE_URL = `${BASE_URL}/update`;

/**
 * [満空情報]満空情報閲覧履歴のプロバイダー.
 */
@Injectable()
export class FacilitySummaryViewHistoryServiceProvider extends ServerlessApiProvider {

  private sessionSource: BehaviorSubject<FacilitySummaryViewHistory> = new BehaviorSubject<FacilitySummaryViewHistory>(null);


  constructor(
    public http: HttpClient
  ) {
    super(http);
  }

  /**
   * セッションを取得する.
   */
   getSession(): FacilitySummaryViewHistory {
    return this.sessionSource.getValue();
  }

  /**
   * セッションを削除する.
   */
  deleteSession() {
    this.sessionSource.next(null);
  }

  /**
   * セッションを保存する.
   */
  saveSession(session: FacilitySummaryViewHistory) {
    this.sessionSource.next(session);
  }

  /**
   * 満空情報の閲覧履歴取得処理.
   * 
   * @param startDt 検索範囲開始日時
   * @param endDt 検索範囲終了日時
   * @returns Observable<FacilitySummaryViewHistoryGetApiResponse>
   */
  public getHistory(startDt: string, endDt: string): Observable<FacilitySummaryViewHistoryGetApiResponse> {
    const params = { startDt: startDt, endDt: endDt } as FacilitySummaryViewHistoryGetApiParameter;
    return this.post(BASE_URL, params);
  }

  /**
   * 満空情報の閲覧履歴の更新処理.
   * 
   * @param id 履歴ID
   * @param visitorId 訪問者ID
   * @param lat 緯度
   * @param lon 経度
   * @param beginViewDt 閲覧開始日時
   * @param endViewDt 閲覧終了日時
   * @returns Observable<FacilitySummaryViewHistoryUpdateApiResponse>
   */
  public update(id?: number, visitorId?: string, lat?: number, lon?: number, beginViewDt?: number, endViewDt?: number): Observable<FacilitySummaryViewHistoryUpdateApiResponse> {
    const params = {
      id: id,
      visitorId: visitorId,
      lat: lat,
      lon: lon,
      beginViewDt: beginViewDt,
      endViewDt: endViewDt
     }
    return this.post(UPDATE_URL, params);
  }
}


/**
 * 予約ステータス タイプ.
 */
export const RequestStatusType = {
  /** 初期状態. */
  PROCESSING: 'processing',
  /** 利用可能な車両無し. */
  NO_DRIVERS_AVAILABLE: 'noDriversAvailable',
  /** 予約中断. */
  SERVICE_DISRUPTION: 'serviceDisruption',
  /** 車両マッチ完了. */
  ACCEPTED: 'accepted',
  /** 車両乗車地付近接近中. */
  ARRIVING: 'arriving',
  /** 目的地移動中. */
  IN_PROGRESS: 'inProgress',
  /** 予約キャンセル. */
  CANCELLED: 'cancelled',
  /** 目的地到着. */
  COMPLETED: 'completed',
};

export type RequestStatusType = typeof RequestStatusType[keyof typeof RequestStatusType];
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ServicerProvider } from './servicer';
import { BoardingLocationInfo } from '../../app/services/servicer/models/masterData';

/**
 * ステーションV2サービスプロバイダー.
 */
@Injectable()
export class StationV2ServiceProvider extends ServicerProvider {
  private sessionSource = new BehaviorSubject<BoardingLocationInfo>(null);

  constructor(
    public http: HttpClient
  ) {
    super(http);
  }

  /**
   * セッションを取得する.
   */
  getSession(): BoardingLocationInfo {
    return this.sessionSource.getValue();
  }

  /**
   * セッションを削除する.
   */
  deleteSession() {
    this.sessionSource.next(null);
  }

  /**
   * セッションを保存する.
   */
  saveSession(session: BoardingLocationInfo) {
    this.sessionSource.next(session);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { FacilityStatusTypeGetApiParameter } from './models/facility-status-type-api-parameter';
import { FacilityStatusTypeGetApiResponse } from './models/facility-status-type-api-response';

import { ServerlessApiProvider } from '../serverless-api';
import { HttpClient } from '@angular/common/http';
import { FacilityStatusType } from './models/facility-status-type';

const BASE_URL = '/v1/backend/facility/facilityStatusType';

/**
 * [満空情報]満空情報種別のプロバイダー.
 */
@Injectable()
export class FacilityStatusTypeServiceProvider extends ServerlessApiProvider {

  private sessionSource: BehaviorSubject<FacilityStatusType> = new BehaviorSubject<FacilityStatusType>(null);

  constructor(
    public http: HttpClient
  ) {
    super(http);
  }

  /**
   * セッションを取得する.
   */
   getSession(): FacilityStatusType {
    return this.sessionSource.getValue();
  }

  /**
   * セッションを削除する.
   */
  deleteSession() {
    this.sessionSource.next(null);
  }

  /**
   * セッションを保存する.
   */
  saveSession(session: FacilityStatusType) {
    this.sessionSource.next(session);
  }

  /**
   * 満空情報種別一覧取得処理.
   * 
   * @returns Observable<FacilityStatusTypeGetApiResponse>
   */
  public list(id?: number): Observable<FacilityStatusTypeGetApiResponse> {
    const params = { id: id } as FacilityStatusTypeGetApiParameter;
    return this.post(BASE_URL, params);
  }
}

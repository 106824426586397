import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserGuestLoginResponse } from './models/user-guest-login-response';
import { UserSession } from './models/user-session';
import { ServicerProvider } from './servicer';
import { CancelRequestParameter } from './models/user-cancel-request-parameter';
import { CancelRequestResponse } from './models/user-cancel-request-response';
import { RetrieveRequestResponse } from './models/user-retrieve-request-response';
import { CreateRequestParameter } from './models/user-create-request-parameter';
import { CreateRequestResponse } from './models/user-create-request-response';
import { ListAllRequestsResponse } from './models/user-list-all-requests-response';
import { ListAllRequestsParameter } from './models/user-list-all-requests-parameter';
import { RetrieveVehicleResponse } from './models/user-retrieve-vehicle-response';
import { SpareGetServiceIdParameter } from './models/spare-get-service-id-parameter';
import { SpareGetServiceIdResponse } from './models/spare-get-service-id-response';
import { SpareGetNextAvailableRideParameter } from './models/spare-get-next-available-ride-parameter';
import { SpareGetNextAvailableRideResponse } from './models/spare-get-next-available-ride-response';
import { RetrieveRiderResponse } from './models/user-retrieve-rider-response';

/**
 * ユーザV2サービスプロバイダー.
 */
@Injectable()
export class UserV2ServiceProvider extends ServicerProvider {
  private sessionSource: BehaviorSubject<UserSession> = new BehaviorSubject<UserSession>(null);

  constructor(
    public http: HttpClient
  ) {
    super(http);
  }

  /**
   * セッションを取得する.
   */
  getSession(): UserSession {
    return this.sessionSource.getValue();
  }

  /**
   * セッションを削除する.
   */
  deleteSession() {
    this.sessionSource.next(null);
  }

  /**
   * セッションを保存する.
   */
  saveSession(session: UserSession) {
    this.sessionSource.next(session);
  }

  /**
   * サービス検索Spare社API
   * @param parameter 
   * @returns 
   */
  public spareGetServiceId(parameter: SpareGetServiceIdParameter): Observable<SpareGetServiceIdResponse> {
    return this.get<SpareGetServiceIdParameter, SpareGetServiceIdResponse>(
      '/v1/estimates/services',
      parameter);
  }

  /**
   * 予約見積Spare社API
   * @param parameter 
   * @returns 
   */
  public spareGetNextAvailableRide(parameter: SpareGetNextAvailableRideParameter): Observable<SpareGetNextAvailableRideResponse> {
    return this.get<SpareGetNextAvailableRideParameter, SpareGetNextAvailableRideResponse>(
      '/v1/estimates/nextAvailable',
      parameter);
  }

  /**
   * ゲストユーザログイン.
   *
   * @returns Observable<UserGuestLoginResponse>
   */
   guestLogin(): Observable<UserGuestLoginResponse> {
    return this.getLogin<UserGuestLoginResponse>(
      '/v1/backend/guestLogin');
  }

  /**
   * 配車キャンセル.
   * 
   * @param parameter CancelRequestParameter
   * @returns Observable<UserDispatchCancelResponse>
   */
  cancelRequest(parameter: CancelRequestParameter): Observable<CancelRequestResponse> {
    return this.post<CancelRequestParameter, CancelRequestResponse>(
      '/v1/requests/' + parameter.id + '/cancellation',
      parameter);
  }

  /**
   * 配車予約.
   * 配車予約を行う。
   *
   * @param parameter CreateRequestParameter
   * @returns Observable<CreateRequestResponse>
   */
  createRequest(parameter: CreateRequestParameter): Observable<CreateRequestResponse>{
    return this.post<CreateRequestParameter, CreateRequestResponse>(
      '/v1/requests',
      parameter);
  }

  /**
   * 予約情報取得.
   * 予約IDで予約ステータスを確認する。
   *
   * @param parameter string
   * @returns Observable<RetrieveRequestResponse>
   */
  retrieveRequest(parameter: string): Observable<RetrieveRequestResponse> {
    return this.get<string, RetrieveRequestResponse>(
      '/v1/requests/' + parameter,
      null);
  }

  /**
   * 車両情報取得.
   * 車両IDで車両情報を取得する。
   *
   * @param parameter string
   * @returns Observable<RetrieveVehicleResponse>
   */
  retrieveVehicle(parameter: string): Observable<RetrieveVehicleResponse> {
    return this.get<string, RetrieveVehicleResponse>(
      '/v1/vehicles/' + parameter,
      null);
  }

  /**
   * 予約一覧取得.
   * 予約一覧を取得する。
   *
   * @param parameter ListAllRequestsParameter
   * @returns Observable<ListAllRequestsResponse>
   */
  listAllRequests(parameter: ListAllRequestsParameter): Observable<ListAllRequestsResponse> {
    return this.get<ListAllRequestsParameter, ListAllRequestsResponse>(
      '/v1/requests',
      parameter);
  }

  /**
   * ユーザー情報取得.
   * ユーザーIDでユーザー情報を取得する。
   *
   * @param parameter string
   * @returns Observable<RetrieveRiderResponse>
   */
  retrieveRider(parameter: string): Observable<RetrieveRiderResponse> {
    return this.get<string, RetrieveRiderResponse>(
      '/v1/riders/' + parameter,
      null);
  }
}

import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { UserV2ServiceProvider } from './user-v2-service';
import { RequestStatusType } from './types/request-status-type';
import { Const } from '../const';
import { Request } from './models/request-vehicle-Info';
import { RequestIdDate } from './models/user-request';

/**
 * 予約表示する予約ステータスではないデータをローカルストレージから削除するプロバイダー
 */
@Injectable()
export class ManageStorageAroundReservationCardsService {

  /** 予約カードを表示する予約ステータスのリスト. */
  private readonly REQUEST_STATUS_LIST_DISPLAY_RESERVATION_CARD = [
    RequestStatusType.ACCEPTED,
    RequestStatusType.ARRIVING
  ];

  /** 予約されている予約ID日付一覧 */
  private storageRequestIdDate: RequestIdDate;

  constructor(
    public userV2Service: UserV2ServiceProvider,
  ) {
  }

  /**
   * ローカルストレージから取得した予約情報内のrequestIdが、入力引数のrequestIdに含まれているか確認する
   * @param reservations  バックエンドから取得した予約情報一覧
   */
  public checkReservationUserId(reservations: Request[]): void {
    // ローカルストレージから予約されているユーザーIDリストを取得
    this.storageRequestIdDate = JSON.parse(localStorage.getItem(Const.requestIdDateListStorageKey));

    if (this.storageRequestIdDate) {
      // 予約一覧のrequestIdリスト作成
      const requestIdList: string[] = reservations.map((reservation: Request) => (reservation.id));
      const storageRequestIdList: string[] = Object.keys(this.storageRequestIdDate).map((requestId) => {
        return requestId;
      });

      //ローカルストレージのrequestIdの数だけループ
      storageRequestIdList.forEach((requestId) => {
        //予約一覧に含まれていない場合
        if (!requestIdList.includes(requestId)) {
          this.deleteReservationInfoFromLocalStorage(requestId);
        }
      })
    }
  }

  /**
   * 予約情報の予約ステータスを確認し、予約カードを表示しないステータスの場合ローカルストレージから削除する
   * @param requestId 予約ID
   */
  private deleteReservationInfoFromLocalStorage(requestId: string): void {
    //予約情報取得APIを実行して予約情報を取得
    this.userV2Service.retrieveRequest(requestId).pipe(
      tap((response) => {
        //ローカルストレージ上に存在するrequestIdのrequestStatusを確認し、requestStatusがaccepted, arriving以外の場合ローカルストレージから削除する
        if (!this.REQUEST_STATUS_LIST_DISPLAY_RESERVATION_CARD.includes(response.status)) {
          //対象データを削除
          delete this.storageRequestIdDate[requestId];
          //対象データ削除後のデータをローカルストレージにセット
          localStorage.setItem(Const.requestIdDateListStorageKey, JSON.stringify(this.storageRequestIdDate));
        }
      }),
      catchError(() => {
        //定期的に実行される処理の為エラーはスルー
        return of(null);
      })
    ).subscribe();
  }
}
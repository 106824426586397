import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, interval, of, throwError } from 'rxjs';
import { tap, map, catchError, filter, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UserV2ServiceProvider } from '../servicer/user-v2-service';
import { ListAllRequestsResponse } from '../servicer/models/user-list-all-requests-response';
import { ManageStorageAroundReservationCardsService } from '../servicer/reservations-check-status';
import { Const } from '../const';
import { RequestStatusType } from '../servicer/types/request-status-type';
import { RequestIdDate } from '../servicer/models/user-request';
import { ApiResultType } from '../servicer/types/api-result-type';

/**
 * 予約一覧を定期間隔で取得するプロバイダー.
 */
@Injectable()
export class ReservationsGetScheduleProvider {

  /** 予約一覧の空のレスポンス. */
  private readonly reservationsEmptyResponse = { data: [] } as ListAllRequestsResponse;

  /** 予約一覧. */
  public reservations$: BehaviorSubject<ListAllRequestsResponse>
    = new BehaviorSubject<ListAllRequestsResponse>(this.reservationsEmptyResponse);

  /** インターバルのSubscription. */
  private intervalSubscription: Subscription = null;

  constructor(
    public userV2Service: UserV2ServiceProvider,
    public manageStorageAroundReservationCardsService: ManageStorageAroundReservationCardsService
  ) {
  }

  /**
   * 開始.
   */
  start() {
    // 初回の予約一覧取得
    this.getReservations().subscribe(() => {
      let processing = false;

      this.intervalSubscription = interval(environment.setting.reservationsGetInterval)
        .pipe(filter(() => !processing))        
        .pipe(tap(() => processing = true))
        .pipe(mergeMap(() => this.getReservations()))
        .pipe(tap(() => processing = false))
        .subscribe();
    });
  }

  /**
   * 停止.
   */
  stop() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
      this.intervalSubscription = null;
    }
  }

  /**
   * 予約一覧を取得します.
   * 定期的に呼び出されるため、エラーが発生しても、何も処理をしません.
   */
  private getReservations(): Observable<ListAllRequestsResponse> {
    const storageRequestIdDate: RequestIdDate = JSON.parse(localStorage.getItem(Const.requestIdDateListStorageKey));
    let storageRequestIdList: string[] = null;

    if(storageRequestIdDate && Object.keys(storageRequestIdDate).length > 0){
      storageRequestIdList = Object.keys(storageRequestIdDate);
    } else {
      return of(null);
    }

    return this.userV2Service
      .listAllRequests({
        ids: storageRequestIdList,
        status: [RequestStatusType.ACCEPTED, RequestStatusType.ARRIVING].join('|')
      })
      .pipe(
        map((response) => {
          if(Object.keys(response).length > 0){
            this.reservations$.next(response);
            this.manageStorageAroundReservationCardsService.checkReservationUserId(response.data);
            return this.reservations$.value;
          } else {
            throwError(() => new Error("Invalid response received"));
          }
        }),
        catchError((error) => {
          // 無効トークンのエラー
          const TOKEN_ERROR_NAME = 'INVALID_TOKEN';
          if(error.error?.name === TOKEN_ERROR_NAME){
            this.userV2Service.guestLogin().subscribe((response) => {
              if (response.result !== ApiResultType.SUCCESS) {
                return of(this.reservations$.value);
              }
              // ゲストログイントークン設定
              environment.token = response.guestLogin.token;
            });
          }
          return of(this.reservations$.value);
        })
      );
  }
}